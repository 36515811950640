import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../files/img/logo.png'

const Navbar = () => {
  const [theme, setTheme] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const ToggleTheme = () => {
    switch (theme) {
      case true:
        document.documentElement.classList.add('light')
        document.documentElement.classList.remove('dark')
        break
      case false:
        document.documentElement.classList.remove('light')
        document.documentElement.classList.add('dark')
        break
      default:
        document.documentElement.classList.add('light')
        break
    }
    return (
      <button type='button' onClick={() => setTheme(!theme)} className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5'>
        {theme === true && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'> </path></svg>)}
        {theme === false && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z' fillRule='evenodd' clipRule='evenodd'> </path></svg>)}
      </button>
    )
  }

  const ButtonsMenu = () => (
    <div
      className='flex items-center justify-center w-full h-full'
    >
      <Link
        to='/no_plastics'
        className='
          bg-red-900
          text-white
          uppercase
          font-medium
          rounded
          m-1
          px-2
          py-1
          text-xs
          sm:md:px-4
          sm:md:py-2
          sm:md:text-sm
          hover:bg-red-500
          focus:outline-none
          focus:bg-red-500'
      >
        No Plastics!
      </Link>
      <Link
        to='/currentmenu'
        className='
          bg-red-900
          text-white
          uppercase
          font-medium
          rounded
          m-1
          px-2
          py-1
          text-xs
          sm:md:px-4
          sm:md:py-2
          sm:md:text-sm
          hover:bg-red-500
          focus:outline-none
          focus:bg-red-500'
      >
        Current Menu
      </Link>
    </div>
  )

  const LinkForComputer = ({ text, path }) => (
    <Link to={path} className='py-4 px-2 delay-150 hover:border-b-2 hover:dark:border-b-green-400 hover:border-b-green-900 text-gray-500 dark:text-gray-400 font-semibold hover:text-green-900 dark:hover:text-green-400 transition duration-300'>{text}</Link>
  )

  const LinkForMobile = ({ text, path }) => (
    <Link to={path} className='block text-sm px-2 py-4 hover:bg-green-700 hover:text-white transition duration-300'>{text}</Link>
  )

  return (
    <nav className='bg-white dark:bg-black/90 shadow-lg'>
      <div className='max-w-6xl mx-auto px-4'>
        <div className='flex justify-between'>
          <div className='flex space-x-7'>
            <div>
              <Link to='/' className='flex items-center py-4 px-2'>
                <img src={logo} alt='Logo' className='h-8 w-8 mr-2' />
                <span className='font-semibold text-black-900 dark:text-white text-lg'>Wok Of Joy</span>
              </Link>
            </div>
            <div className='hidden md:flex items-center justify-center lg:pl-[3rem] lg:space-x-1'>
              <LinkForComputer text='Joy’s Food' path='/joysfood' />
              <LinkForComputer text='Joy’s Story' path='/joysstory' />
              <LinkForComputer text='Contact Us' path='/contactus' />
            </div>
          </div>
          <div className='flex min-h-full items-center'>
            <ButtonsMenu />
          </div>
          <div className='hidden md:flex items-center space-x-3 '>
            <ToggleTheme />
          </div>
          <div onClick={() => setIsOpen(!isOpen)} className='md:hidden flex items-center'>
            <button className='outline-none mobile-menu-button'>
              <svg
                className=' w-6 h-6 text-gray-500 hover:text-green-500 dark:text-white'
                x-show='!showMenu'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path d='M4 6h16M4 12h16M4 18h16'> </path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className='mobile-menu'>
          <ul className=''>
            <LinkForMobile text='Joy’s Food' path='/joysfood' />
            <LinkForMobile text='Joy’s Story' path='/joysstory' />
            <LinkForMobile text='Contact Us' path='/contactus' />
            <ToggleTheme />
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Navbar
