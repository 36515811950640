import React from 'react'

const NoPlastics = () => {
  return (
    <div className='bg-neutral-100 h-full dark:bg-slate-800 flex  items-center flex-col mb-8 p-6'>
      <h2 className='sm:md:text-3xl text-xl font-medium text-gray-800/90 dark:text-gray-100 capitalize m-5'>
        The Wok of Joy and the Town of Mountain Village
      </h2>
      <div className='flex justify-center items-center mb-4'>
        <p className='text-sm text-gray-600 dark:text-gray-200 bg-red-100 dark:bg-red-900/70 rounded-xl p-4 m-1'>
          <strong>Are committed to eliminating single-use garbage plastics.</strong>
        </p>
      </div>
      <hr className='text-slate-600 dark:text-slate-900 my-2' />
      <h2 className='text-xl font-semibold mb-4'>Following the town’s rules, The Wok only uses:</h2>
      <ul className='list-disc list-inside mb-4 space-y-2'>
        <li>PLA (plant-based) compostable bio containers</li>
        <li>Plant fiber sugarcane containers</li>
        <li>Corn-based PLA compostable cups</li>
        <li>Agave resin straws</li>
        <li>PLA resin forks and spoons</li>
        <li>Recycled paper bags</li>
      </ul>

      <p className='mb-4 md:w-1/2 text-center'>The cost of using environmentally responsible products and bags is factored into our pricing as required by the town.</p>
      <p className='mb-4 font-bold'>Please bring your own bags every time.</p>

      <div className='animate-bounce mt-8 bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth='2'>
          <path strokeLinecap='round' strokeLinejoin='round' d='M19 14l-7 7m0 0l-7-7m7 7V3' />
        </svg>
      </div>

      <a href='https://townofmountainvillage.com/green-living/planet-over-plastics/' className='bg-white mt-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'>Learn more</a>
    </div>
  )
}

export default NoPlastics
