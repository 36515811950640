import React from 'react'
import bgstory from '../files/2024/cart-two.jpg'
import Laos1 from '../files/2024/laos1.jpg'
import Laos2 from '../files/2024/laos2.jpg'
import Laos3 from '../files/2024/laos3.jpg'
import Cart1 from '../files/2024/CART 1.jpeg'
import Brick1 from '../files/2024/BRICK 1.jpeg'
import Brick2 from '../files/2024/BRICK 2.jpeg'
import Trailer from '../files/contact/trailer.webp'

const JoysStory = () => {
  return (
    <div className='bg-neutral-100 dark:bg-slate-900'>
      <header>
        <div className='w-full block bg-cover bg-center' style={{ backgroundImage: `url(${bgstory})`, height: '32rem' }}>
          <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
            <div>
              <div className='text-center p-3 m-3'>
                <h5 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-white'>
                  Joy's Story
                </h5>
              </div>
              <div className='text-center md:w-[50%]  md:mx-auto p-3 m-3 rounded backdrop-blur-xl shadow-lg'>
                <p className='pb-3 md:w-[80%] md:mx-auto font-semibold uppercase sm:text-xs md:text-sm text-white'>
                  JOY’S FIRST RESTAURANT WAS CALLED MORNING GLORY CAFE IN THE UNESCO WORLD HERITAGE SITE OF LUANG PRABANG, LAOS IN 2005.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='container mx-auto'>
        <section className='pt-8 pb-8 bg-neutral-100 dark:bg-slate-900'>
          <div className='text-center mt-8 md:mr-0 flex flex-col md:flex-row md:flex-wrap gap-2'>
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Laos1} alt='Laos' />
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Laos2} alt='Laos' />
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Laos3} alt='Laos' />
          </div>

          <div className='text-center p-3 mt-6 shadow-2xl rounded bg-neutral-100 container mx-auto dark:bg-gray-800'>
            <div className='w-full'>
              <p className='pb-3 md:w-[45%] md:mx-auto font-semibold uppercase sm:text-xs md:text-sm text-gray-600 dark:text-gray-400'>
                AFTER MOVING TO TELLURIDE IN 2013 SHE OPENED THE WOK OF JOY FOOD CART IN THE MOUNTAIN VILLAGE PLAZA CORE.
              </p>
            </div>
          </div>
          <div className='text-center mt-8 md:mr-0 flex flex-col md:flex-row md:flex-wrap gap-2'>
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Cart1} alt='Telluride Cart' />
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={bgstory} alt='Telluride Cart' />
          </div>
          <div className='text-center p-3 mt-6 shadow-2xl rounded bg-neutral-100 container mx-auto dark:bg-gray-800'>
            <div className='w-full'>
              <p className='pb-3 md:w-[35%] md:mx-auto font-semibold uppercase sm:text-xs md:text-sm text-gray-600 dark:text-gray-400'>
                THE SUCCESS OF THAT VENTURE LED TO HER BRICK AND MORTAR RESTAURANT IN THE HEART OF TELLURIDE IN 2021.
              </p>
            </div>
          </div>
          <div className='text-center mt-8 md:mr-0 flex flex-col md:flex-row md:flex-wrap gap-2'>
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Brick1} alt='Telluride Cart' />
            <img className='rounded h-full w-[80%] md:w-[365px] md:h-[450px] mx-auto' src={Brick2} alt='Telluride Cart' />
          </div>
          <div className='text-center p-3 mt-6 shadow-2xl rounded bg-neutral-100 container mx-auto dark:bg-gray-800'>
            <div className='w-full'>
              <p className='pt-3 font-semibold uppercase md:text-2xl sm:text-1xl text-red-600 dark:text-red-400'>
                The Wok of Joy
              </p>
              <hr />
              <p className='pb-3 mt-6 md:w-[40%] md:mx-auto font-semibold uppercase sm:text-xs md:text-sm text-gray-600 dark:text-gray-400'>
                THE WOK OF JOY DECIDED TO RETURN TO THEIR ROOTS IN 2024 BY OPENING A VINTAGE FOOD TRAILER IN THE CONFERENCE CENTER PLAZA IN THE MOUNTAIN VILLAGE CORE. THIS IS WHERE YOU CAN FIND US TODAY.
                <br />
                THE WOK OF JOY’S LITTLE GREEN FOOD TRAILER
                <br />
                IN THE SUMMER OF 2024, JOY DOWNSIZED AND RETURNED TO HER FOOD VENDING ROOTS TO PURSUE A BETTER, HAPPIER LIFESTYLE.
                <br />
                YOU CAN NOW FIND HER COOKING IN HER CUSTOM VINTAGE FOOD TRAILER PERMANENTLY PARKED IN THE MOUNTAIN VILLAGE CORE’S CONFERENCE CENTER PLAZA.
                <br />
                PROPER THAI.
              </p>
            </div>
          </div>
          <div className='text-center mt-8 md:mr-0 flex flex-col md:flex-row md:flex-wrap gap-2'>
            <img className='rounded h-full w-[60%] mx-auto' src={Trailer} alt='Telluride Cart' />
          </div>
        </section>
      </div>
    </div>
  )
}

export default JoysStory
