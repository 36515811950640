// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.video-wrapper video {
  height: 100%;
  position: absolute;
    top: 0;
    left: 0;
  width: 100%;
}

.video-title {
  background-color: '#010101' !important;
  position: absolute;
    top: 50%;
    left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.organic-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.organic__logo {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;IAChB,MAAM;IACN,OAAO;EACT,WAAW;AACb;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;IAChB,QAAQ;IACR,SAAS;EACX,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".video-wrapper {\n  height: 0;\n  padding-bottom: 56.25%;\n  position: relative;\n  width: 100%;\n}\n\n.video-wrapper video {\n  height: 100%;\n  position: absolute;\n    top: 0;\n    left: 0;\n  width: 100%;\n}\n\n.video-title {\n  background-color: '#010101' !important;\n  position: absolute;\n    top: 50%;\n    left: 50%;\n  transform: translateX(-50%);\n  z-index: 99;\n}\n\n.organic-container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.organic__logo {\n  border-radius: 50%;\n  margin-bottom: 1rem;\n  width: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
