const token = '44f45a89692e2c5e2dfdb1dcc6b3d5c2a87ef0f3efdc3d5474d6aba9704ff72ec9ce00db7c4b4dcd30af3b446d34c614d658b8f5ca404f1c0b19c081ebd2b81b0ae61e2bca7e84925a9d57a33d9b66f7f8fb0a767b7ec4841ae4b73c21507817315448e0f4b1476da9b92eaa9032f116b74b68628c7856a8e6c20c17c6b3c3a9'
const STRAPI_URL = 'https://the-wok-of-joy-strapi.telluridedigitalworks.com/strapi'

export const getStrapiHome = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        heroTitle: attributes?.hero_title,
        cardTitle: attributes?.card_title,
        facebookLabel: attributes?.facebook_label,
        plantswitchLogo: STRAPI_URL + attributes?.plantswitch_logo?.data?.attributes?.url,
        plantswitchTitle: attributes?.planswitch_title,
        plantswitchSubtitle: attributes?.planswitch_subtitle,
        subtitleOne: attributes?.subtitle_one,
        subtitleTwo: attributes?.subtitle_two,
        subtitleSchedule: attributes?.subtitle_schedule,
        subtitleDays: attributes?.subtitle_days
      }
    }
  )

  return values
}
