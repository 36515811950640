import bgcontact from '../files/2024/contact-us.jpg'
import { MdMailOutline } from 'react-icons/md'
import market1 from '../files/contact/market_one.webp'
import market2 from '../files/contact/market_two.webp'

const ContactUs = () => {
  return (
    <div className='bg-neutral-100 dark:bg-slate-900'>
      <header>
        <div className='w-full bg-cover bg-center' style={{ backgroundImage: `url(${bgcontact})`, height: '32rem' }}>
          <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
            <div className='text-center p-3 m-3 w-[80%]'>
              <h1 className='pb-3 text-dark text-white text-2xl font-semibold uppercase md:text-3xl'>AUTHENTIC THAI STREET FOOD IN THE MOUNTAIN VILLAGE CORE</h1>
            </div>
          </div>
        </div>
      </header>
      <div className='container mx-auto px-4'>
        <section className='bg-neutral-100 shadow p-5 dark:bg-slate-900'>
          <div className='flex justify-center items-center flex-col'>
            <a href='https://www.facebook.com/thewokofjoy' className='my-6 m text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55'>
              <svg className='mr-2 -ml-1 w-4 h-4' aria-hidden='true' focusable='false' data-prefix='fab' data-icon='facebook-f' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='currentColor' d='M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z'> </path></svg>
              FOLLOW US ON FACEBOOK TO SEE WHAT’S HAPPENING
            </a>
            <div className='animate-bounce bg-white dark:bg-slate-800 p-2 w-10 mb-4 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth='2'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M19 14l-7 7m0 0l-7-7m7 7V3' />
              </svg>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex justify-center items-center m-3 p-2 rounded bg-neutral-100 dark:bg-slate-800 shadow'>
              <img
                src={market1}
                alt='market'
                className='w-full h-auto object-cover rounded'
              />
            </div>
            <div className='flex justify-center items-center m-3 p-2 rounded bg-neutral-100 dark:bg-slate-800 shadow'>
              <img
                src={market2}
                alt='market'
                className='w-full h-auto object-cover rounded'
              />
            </div>
          </div>
          <div className='grid sm:md:grid-cols-2 grid-cols-1 '>
            <div>
              <div className='text-center m-3 p-10 rounded bg-neutral-100 dark:bg-slate-800 shadow'>
                <div>
                  <h5 className='text-1md font-semibold uppercase md:text-2md sm:text-1sm text-red-900 dark:text-red-200'>Our <br />Location</h5>
                  <hr />
                  <p className='pt-3 font-semibold md:text-2md sm:text-1sm text-red-900 dark:text-red-400'>
                    CONFERENCE CENTER PLAZA IN THE MOUNTAIN VILLAGE CORE <br /> EASILY ACCESSIBLE BY GONDOLA FROM THE TOWN OF TELLURIDE
                  </p>
                </div>
              </div>
            </div>
            <div className='text-center m-3 p-10 rounded bg-neutral-100 dark:bg-slate-800 shadow'>
              <div>
                <h5 className='text-1md font-semibold uppercase md:text-2md sm:text-1sm text-red-900 dark:text-red-200'>Our HOURS</h5>
                <hr />
                <p className='pt-3 font-semibold md:text-2md sm:text-1sm text-red-900 dark:text-red-400'>
                  TUESDAY - SATURDAY <br />11:30 - 7:30 <br /> CLOSED ON SUNDAYS and MONDAYS
                </p>
              </div>
            </div>
            <div className='sm:md:col-span-2 col-span-1 text-center m-3 rounded bg-neutral-100 shadow p-5 dark:bg-slate-800'>
              <hr className='pt-2' />
              <div className='grid grid-cols-1 md:sm:grid-cols-3 gap-2 justify-items-center'>
                <a className='flex items-center text-center text-red-900 dark:text-red-400 p-4' href='mailto:thewokofjoy@gmail.com'>
                  <MdMailOutline />
                  thewokofjoy@gmail.com
                </a>
                <a className='flex items-center text-center text-red-900 dark:text-red-400 p-4' href='https://www.instagram.com/thewokofjoy/'>
                  <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'><path fillRule='evenodd' d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z' clipRule='evenodd'> </path></svg>
                  thewokofjoy
                </a>
                <a className='flex items-center text-center text-red-900 dark:text-red-400 p-4' href='https://www.facebook.com/thewokofjoy'>
                  <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 24 24' aria-hidden='true'><path fillRule='evenodd' d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z' clipRule='evenodd'> </path></svg>
                  thewokofjoy
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ContactUs
