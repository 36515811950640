import React, { useState, useEffect } from 'react'
// import pdfUrl from '../files/THE-WOK-OF-JOY-MENU.pdf'
import CardMenu from '../components/CardMenu'
import { getStrapiCollections } from './getStrapiColletions'

const NewMenu = () => {
  const [mealsWok, setMealsWok] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCollections('wok-menus')
      const sortedData = data.slice().sort((a, b) => a.id - b.id)
      setMealsWok(sortedData)
    }
    fetchData()
  }, [])

  return (
    <div className='bg-neutral-100 dark:bg-slate-800'>
      <div className='container mx-auto text-center'>
        <h2 className='sm:md:text-3xl text-xl font-medium text-gray-800/90 dark:text-gray-100 capitalize m-5'>
          SUMMER MENU <br /> (PRICE INCLUDES ALL TAXES)
        </h2>
        {/* <div className='pb-4'>
          <a href={pdfUrl} rel='noopener noreferrer' target='_blank' download='THE-WOK-OF-JOY-MENU.pdf' className='w-[150px] mx-auto bg-red-900 text-white uppercase font-medium rounded m-1 px-2 py-1 text-xs sm:md:px-4 sm:md:py-2 sm:md:text-sm hover:bg-red-500 focus:outline-none focus:bg-red-500'>
            <button className='modern-button' label='Resume'>
              Download Menu
            </button>
          </a>
        </div> */}
        <div className='flex justify-center items-center mb-4'>
          <p className='text-sm text-gray-600 dark:text-gray-200 bg-red-100 dark:bg-red-900/70 rounded-xl w-1/2 p-1 m-1'>
            <strong>Please bring your own bag. please.</strong>
          </p>
        </div>
        <hr className='text-slate-600 dark:text-slate-900 my-2' />
        {mealsWok?.map(({ id, category, description, meals, header }) => (
          <div className='mx-5 my-8' key={id} id={id}>
            <h2 className='sm:md:text-3xl text-4xl font-medium text-gray-900 dark:text-gray-200/70 capitalize p-2'>{header}</h2>
            {description?.length > 0 && (<p id={`${id}-description`} className='text-gray-600 dark:text-gray-400'> {description} </p>)}
            <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:xl:grid-cols-3 sm:md:mx-0 mx-5 gap-2 text-center justify-items-center' id={category}>
              {/* eslint-disable */}
              {meals.map(({ title, id, index, brief_description, ingredients }) => (
                 <div key={id} className='flex justify-center items-center my-3 max-w-sm w-full rounded-lg dark:bg-slate-600/50 ring-1 ring-slate-900/5 shadow-md bg-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-200 h-[160px]'>
                  <CardMenu title={title} subtitle={brief_description} section={header} description={ingredients} />
                </div>
              ))}
              {/* eslint-enable */}
            </div>
          </div>
        ))}
        {/* <div className='bg-transparent rounded-sm text-xs p-10'>
          <h2 className='uppercase text-md text-red-400 dark:text-red-600'> !important </h2>
          <br />
          <p className='text-gray '>
            - we use peanuts, fish sauce, all natural meat & poultry, eggs, salt, sugar, and wheat products in our preparations
            <br />
            - please inform the cashier or online order or any dietary restrictions or allergies when ordering
            <br />
            <br />
            Gluten-free options : ask the cashier
            <br />
            Vegan options : ask the cashier which dishes can be made vegan
            <br />
            Seasonal : fresh fruit and veggies are seasonal so we may make substitutions as needed
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default NewMenu
