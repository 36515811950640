import { RiArrowGoBackFill } from 'react-icons/ri'
import { useState } from 'react'

const CardMenu = ({ title, subtitle, section, description }) => {
  const [isOpen, setIsOpen] = useState(false)

  const CardElements = ({ title, subtitle, description }) => (
    <>
      <h5 className='mb-1 text-xl font-medium text-slate-900/80 dark:text-neutral-100'>{title}</h5>
      {subtitle?.length > 0 && <p className='text-sm text-neutral-600 dark:text-neutral-400'>{subtitle}</p>}
      <div className='flex items-center'>
        {description?.length > 0 && (
          <button onClick={() => setIsOpen(!isOpen)} className='text-sm text-gray-600/80 dark:text-gray-200 bg-sky-200 dark:bg-sky-900/70 rounded-xl p-1 m-1'>🧂 Ingr...</button>
        )}
      </div>
    </>
  )

  const CardDescription = ({ description }) => (
    <>
      <p className='sm:md:text-sm text-xs text-gray-500 dark:text-gray-400'>{description}</p>
      <button onClick={() => setIsOpen(!isOpen)} className='text-sm text-gray-600/80 dark:text-gray-200 bg-sky-200 dark:bg-sky-900/70 rounded-xl p-1 px-3 m-1'><div className='flex items-center'><RiArrowGoBackFill /> Back..</div></button>
    </>
  )

  return (
    <div className='flex flex-col items-center p-10'>
      {!isOpen ? (<CardElements section={section} subtitle={subtitle} title={title} description={description} />) : (<CardDescription description={description} />)}
    </div>
  )
}

export default CardMenu
