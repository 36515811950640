// Components with react-router-dom
import { Routes, Route } from 'react-router-dom'
// Components of the System
import Footer from './assets/components/Footer'
import Navbar from './assets/components/Navbar'
import JoysStory from './assets/routes/JoysStory'
import ContactUs from './assets/routes/ContactUs'
import NoPlastics from './assets/routes/NoPlastics'
import Home from './assets/routes/Home'
import JoysFood from './assets/routes/JoysFood'
import NewMenu from './assets/routes/NewMenu'

const App = () => {
  return (
    <div className='flex flex-col h-screen justify-between antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-800'>
      <Navbar className='h-10' />
      <Routes className='mb-auto h-10'>
        <Route path='/' element={<Home />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/joysstory' element={<JoysStory />} />
        <Route path='/joysfood' element={<JoysFood />} />
        <Route path='/no_plastics' element={<NoPlastics />} />
        <Route path='/currentmenu' element={<NewMenu />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <Footer className='h-10' />
    </div>
  )
}

export default App
